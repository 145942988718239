<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Previous Monthly Templates</h1>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search Templates"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-btn
              depressed
              class="mr-2"
              :to="{ name: 'module-flightmode-monthlytemplates' }"
              >Current Templates</v-btn
            >
          </v-row>
        </v-col>
      </v-row>
      <v-data-table
        :headers="tableHeaders"
        :items="templates"
        no-data-text="No Templates found"
      >
        <template v-slot:item.month_year="{ item }">
          {{ item.month_name }} {{ item.year }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="red lighten-4 red--text"
                v-on="on"
                @click="openDelete(item)"
              >
                <v-icon x-small>mdi-archive</v-icon>
              </v-btn>
            </template>
            <span>Delete</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-container>
    <v-dialog v-model="deleteDialog.open" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Archive Monthly Template</v-card-title>
        <v-card-text
          >Are you sure you want to archive this monthly template?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetDelete">No, cancel</v-btn>
          <v-btn
            color="success"
            depressed
            :loading="deleteDialog.loading"
            @click="saveDelete"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchTerm: "",
      breadcrumbs: [
        {
          text: "Monthly Templates",
          disabled: false,
          exact: true,
          to: {
            name: "module-flightmode-monthlytemplates",
          },
        },
        {
          text: "Previous",
          disabled: true,
        },
      ],
      tableHeaders: [
        { text: "Month/Year", value: "month_year" },
        { text: "Theme", value: "theme" },
        { text: "Category", value: "category" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
      deleteDialog: {
        open: false,
        loading: false,
        template: {},
      },
    };
  },

  computed: {
    templates() {
      let templates = this.$store.state.flightmode.monthlyTemplates[
        "templates"
      ];
      const searchTerm = this.searchTerm.toLowerCase();
      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth() + 1;

      templates = templates.filter((t) => {
        return (
          parseInt(t.year) < currentYear ||
          (parseInt(t.year) === currentYear && parseInt(t.month) < currentMonth)
        );
      });

      if (this.searchTerm !== "") {
        templates = templates.filter((t) => {
          const monthYear = (t.month_name + " " + t.year).toLowerCase();
          const theme = t.theme.toLowerCase();
          const category = t.category.toLowerCase();
          return (
            monthYear.includes(searchTerm) ||
            theme.includes(searchTerm) ||
            category.includes(searchTerm)
          );
        });
      }

      return templates;
    },
  },

  methods: {
    openDelete(template) {
      this.deleteDialog.template = template;
      this.deleteDialog.open = true;
    },

    resetDelete() {
      this.deleteDialog.loading = false;
      this.deleteDialog.open = false;
      this.deleteDialog.template = {};
    },

    saveDelete() {
      const appId = this.$route.params.id;
      this.deleteDialog.loading = true;

      this.$store
        .dispatch("flightmode/monthlyTemplates/deleteTemplate", {
          appId,
          templateId: this.deleteDialog.template.id,
        })
        .then(() => this.resetDelete())
        .catch(() => (this.loading = false));
    },
  },
};
</script>
